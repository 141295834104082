import React, { useEffect, useRef } from 'react';

import * as contactStyles from './contact.module.css';

import { FaYoutube, FaInstagram, FaFacebook } from "react-icons/fa";

import { setPreset } from '../three/src/main';
import { gsap } from 'gsap';

import { Helmet } from "react-helmet"

const Contact = ({ transitionStatus }) => {
    const container = useRef(null);

    useEffect(() => {
        setPreset(3);
        gsap.to(container.current.children, { opacity: 1, duration: 1, stagger: 0.1 })
    }, [])

    useEffect(() => {
        if(transitionStatus === 'exiting') {
            gsap.to(container.current.children, { opacity: 0, duration: 1, stagger: 0.1 })
        }
    }, [transitionStatus]);

    return (
        <>
            <Helmet>
                <title>Contact | Luka Batista</title>
            </Helmet>
            <div className={contactStyles.container}>
                <div className={contactStyles.box} ref={container}>
                    <div className={contactStyles.email}>
                        <a rel="noreferrer" target="_blank" href="mailto:info@lukabatista.com">info@lukabatista.com</a>
                    </div>
                    <div className={contactStyles.icons}> 
                        <div>
                            <a rel="noreferrer" target="_blank" href="https://www.youtube.com/channel/UC_k1HWrARU9dLJ95bDHwwbw">
                                <FaYoutube />
                            </a>
                        </div>
                        <div>
                            <a rel="noreferrer" target="_blank" href="https://www.instagram.com/lukabatistamusic/">
                                <FaInstagram />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contact;